import React, { useEffect } from "react"
import { addPropertyControls } from "framer"
import _ from "@rive-app/react-canvas"
import RiveComponent, {
    componentName,
    propertyControls,
} from "https://rive.app/api/s/-h-100oT-02KCVVQjTYtMg/framer/"

/**
 * @framerSupportedLayoutWidth any-prefer-fixed
 * @framerSupportedLayoutHeight any-prefer-fixed
 */
export default RiveComponent as React.ComponentType
RiveComponent.displayName = componentName

addPropertyControls(RiveComponent, propertyControls)
